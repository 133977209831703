import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { LiffProvider } from "react-liff";

import App from "./App";
import store from "./services/Store";

// import css lib
import "jbox/dist/jBox.all.css";

// import css
// import "./assets/css/designsystem.css";
// import "./assets/css/404.css";
import "./assets/css/style.css";
// import "./assets/css/index.css";

import "./assets/css/register.css";
import "./assets/css/policy.css";

import "./assets/css/profile.css";
import "./assets/css/edit-profile.css";

// import "./assets/css/warranty.css";
// import "./assets/css/warranty-details.css";
// import "./assets/css/coupon.css";

let initialized = false;

const fnHandleLiff = (liff) => {
  if (initialized === false) {
    initialized = true;

    if (typeof liff.error !== "undefined") {
      console.warn("error", liff.error);
    }

    // liff.ready.then(() => {
    //   console.log("ready", liff.isLoggedIn());

    //   liff
    //     .getProfile()
    //     .then((profile) => {
    //       console.log("profile", profile);
    //     })
    //     .catch((err) => {
    //       console.log("error", err);
    //     });
    // });
  }
};

const liffId = "2004036578-yxK9eWPE";
let isLoginBrowser = true;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <LiffProvider
      liffId={liffId}
      withLoginOnExternalBrowser={isLoginBrowser}
      callback={fnHandleLiff}
    >
      <App />
    </LiffProvider>
  </Provider>
);
