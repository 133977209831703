import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HeaderPageTitle from "../../../layouts/inc/HeaderPageTitle";

import img_imgProfile from "../../../assets/img/ci/profile/img-profile.png";
import img_icoArrowRight from "../../../assets/img/ci/profile/ico-arrow-right.svg";
import img_imgIconUser from "../../../assets/img/ci/profile/img-icon-user.svg";
import img_icoPdpa from "../../../assets/img/ci/profile/ico-pdpa.svg";
import img_icoPolicy from "../../../assets/img/ci/profile/ico-policy.svg";
import img_icoWebsite from "../../../assets/img/ci/profile/ico-website.svg";
import img_icoMail from "../../../assets/img/ci/profile/ico-mail.svg";

const Profile = () => {
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageProfile">
        <main>
          <div className="wrapContent">
            <HeaderPageTitle title="โปรไฟล์" prevPage="/member" />

            <section id="pProfile">
              <div className="bProfile">
                <div className="bTitle">
                  <div className="bImg">
                    {/* <img src={img_imgProfile} alt="" /> */}
                    <img src={stateProfile.lineImgUrl} alt="" />
                  </div>
                  <p className="tName">{stateProfile.dataUser.fullname}</p>
                  <p>ID: {stateProfile.id}</p>
                </div>

                <div className="bProfileSelect">
                  <div className="bMenu" onClick={handleGotoPage} data-page="profile-detail">
                    <div className="bIcon">
                      <img src={img_imgIconUser} alt="" />
                      <p>โปรไฟล์</p>
                    </div>
                    <img src={img_icoArrowRight} alt="" />
                  </div>
                  <div
                    className="bMenu"
                    onClick={handleGotoPage}
                    data-page="term-and-conditions"
                  >
                    <div className="bIcon">
                      <img src={img_icoPdpa} alt="" />
                      <p>ข้อตกลงและเงื่อนไข</p>
                    </div>
                    <img src={img_icoArrowRight} alt="" />
                  </div>
                  <div
                    className="bMenu"
                    onClick={handleGotoPage}
                    data-page="privacy-policy"
                  >
                    <div className="bIcon">
                      <img src={img_icoPolicy} alt="" />
                      <p>นโยบายความเป็นส่วนตัว</p>
                    </div>
                    <img src={img_icoArrowRight} alt="" />
                  </div>
                </div>
                <div className="bContact">
                  <p>ติดต่อเรา</p>
                  <div className="group-contract">
                    <div className="tContact">
                      <img src={img_icoWebsite} alt="" />
                      <a href="#">เว็บไซต์ TPD Paypoint</a>
                    </div>
                    <div className="tContact">
                      <img src={img_icoMail} alt="" />
                      <p>paypoint@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Profile;
