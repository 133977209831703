import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";
import { PatternFormat } from "react-number-format";
import { searchCustomer } from "../../../services/Api/Module/Customer";

import Helper, {
  convertMobileFormat,
  convertIdCardFormat,
  breakKeyDownEnter,
  checkIdCard,
} from "../../../services/helper";

// import useTranslations from "../../../i18n/useTranslations";

import HeaderPageTitle from "../../../layouts/inc/HeaderPageTitle";

import img_icoTitleLogo from "../../../assets/img/ci/ico-title-logo.svg";

const RegisterUser = () => {
  // const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  // const stateProfile = useSelector((state) => state.profile);

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("register");

  const PageRoute = {
    home: "/",
    "auth-otp": "/auth-otp",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      fullname: "",
      mobileNo: "",
      idCard: "",
      email: "",
      birthDate: "",
      gender: "",
      isAgreeAndCon: false,
      isPdpa: false,
    },
  });

  // console.log("isValid: ", isValid);
  const [formIsValid, setFormIsValid] = useState(false);

  const handleCheckOnChange = (_ele = "") => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    const _chkField = [
      "fullname",
      "mobileNo",
      "idCard",
      "birthDate",
      "gender",
      "isAgreeAndCon",
      "isPdpa",
    ];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (_chkField.includes(key)) {
        if (val === "" || val === false || val === null) {
          _invalid = true;
        }
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        // console.log(_value, _value.length);

        if (_value.length === 10) {
          if (_ele === key) {
            _invalid = false;

            handleCheckDataExists(_ele, _value);
          }
        } else {
          _invalid = true;
        }
      }

      if (key === "idCard") {
        clearErrors("idCard");

        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        // console.log(_value, _value.length);

        if (_value.length === 13) {
          if (_ele === key) {
            _invalid = false;

            if (Helper.FNFORM.idCardCheck(_value)) {
              handleCheckDataExists(_ele, _value);
            } else {
              _invalid = true;

              setError("idCard", {
                type: "manual",
                message: "กรุณากรอกหมายเลขบัตรประชาชนให้ถูกต้อง",
              });
            }
          }
        } else {
          _invalid = true;
        }
      }

      if (key === "email") {
        clearErrors("email");

        if (val !== "") {
          let _value = val;
          _value = _value.trim();

          const _regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

          // console.log(_value, _regexEmail.test(_value));

          if (_regexEmail.test(_value)) {
            if (_ele === key) {
              _invalid = false;

              handleCheckDataExists(_ele, _value);
            }
          } else {
            _invalid = true;

            setError("email", {
              type: "manual",
              message: "กรุณากรอกอีเมลให้ถูกต้อง",
            });
          }
        }
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    setDataRegis({
      ...data,
      mobileNo: convertMobileFormat(data.mobileNo),
      idCard: convertMobileFormat(data.idCard),
    });

    // console.log('onSubmit >>', data);
    goNext("auth-otp");
  };

  const setDataRegis = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };
  /* End Handle click submit form */

  /* onClick privacy policy save form data temp  */
  const setTempDataRegis = () => {
    const updateData = {
      fullname: watch("fullname"),
      mobileNo: convertMobileFormat(watch("mobileNo")),
      idCard: convertIdCardFormat(watch("idCard")),
      email: watch("email"),
      birthDate: watch("birthDate"),
      gender: watch("gender"),
      isAgreeAndCon: watch("isAgreeAndCon"),
      isPdpa: watch("isPdpa"),
    };
    // console.log("setTempDataRegis >>", updateData);
    setDataRegis(updateData);
  };
  /* End onClick privacy policy save form data temp */

  /* Check data exists real-time on server-side  */
  const handleCheckDataExists = (field, value) => {
    // console.log(field, value);

    clearErrors(field);

    let payload = {
      q: "register",
    };

    if (field === "mobileNo") {
      payload = {
        ...payload,
        mobileNo: value,
      };
    } else if (field === "idCard") {
      payload = {
        ...payload,
        idCard: value,
      };
    } else if (field === "email") {
      payload = {
        ...payload,
        email: value,
      };
    }

    getSearchCustomer(payload);
  };

  const getSearchCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchCustomer({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSearchCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        if (data.resultCode === 40301) {
          setFormIsValid(false);

          if (payload.mobileNo) {
            setError(
              "mobileNo",
              {
                type: "manual",
                message: `หมายเลขโทรศัพท์นี้มีผู้ใช้งานแล้ว`,
              },
              {
                shouldFocus: true,
              }
            );
          } else if (payload.idCard) {
            setError(
              "idCard",
              {
                type: "manual",
                message: `หมายเลขบัตรประชาชนนี้มีผู้ใช้งานแล้ว`,
              },
              {
                shouldFocus: true,
              }
            );
          } else if (payload.email) {
            setError(
              "email",
              {
                type: "manual",
                message: `อีเมลนี้มีผู้ใช้งานแล้ว`,
              },
              {
                shouldFocus: true,
              }
            );
          }
        }
      }
    } catch (e) {
      console.log("ERR getSearchCustomer >> ", e);
    }

    return _return;
  };
  /* End Check data exists real-time on server-side  */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custCode !== null) {
    //   navigate("/profile");
    // }

    const { dataUser } = stateRegister;

    if (Object.keys(dataUser).length !== 0) {
      setValue("fullname", dataUser.fullname);

      setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"));
      handleCheckDataExists("mobileNo", dataUser.mobileNo);

      setValue("idCard", convertIdCardFormat(dataUser.idCard, "-"));
      handleCheckDataExists("idCard", dataUser.idCard);

      if (dataUser.email) {
        setValue("email", dataUser.email, { shouldValidate: true });
        handleCheckDataExists("email", dataUser.email);
      }

      setValue("birthDate", dataUser.birthDate);
      setValue("gender", dataUser.gender);
      setValue("isAgreeAndCon", dataUser.isAgreeAndCon);
      setValue("isPdpa", dataUser.isPdpa);

      let _chkFormIsValid = true;
      const _chkField = [
        "fullname",
        "mobileNo",
        "idCard",
        "birthDate",
        "gender",
        "isAgreeAndCon",
        "isPdpa",
      ];
      Object.entries(dataUser).forEach(([key, val]) => {
        if (_chkField.includes(key)) {
          if (val === "" || val === false || val === null) {
            _chkFormIsValid = false;
          }
        }
      });

      if (_chkFormIsValid === true) {
        setFormIsValid(true);
      }
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageRegisterNew">
        <main>
          <div className="wrapContent">
            <HeaderPageTitle title="ลงทะเบียน" prevPage="/" />

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pRegisterMain">
                <div className="bRegister">
                  <div className="bTitle">
                    <img src={img_icoTitleLogo} alt="" />
                    <div className="pageStep">
                      <div className="step active"></div>
                      <div className="step"></div>
                      <div className="step"></div>
                    </div>
                    <p>ลงทะเบียนอีกเพียง 2 ขั้นตอนก็จะเสร็จสิ้น</p>
                  </div>

                  <div className="bForm">
                    <div className="bRow">
                      <div
                        className={`control-group validate ${
                          errors.fullname ? "error" : ""
                        }`}
                      >
                        <div className="tTitle ">ชื่อ-นามสกุล</div>
                        <Controller
                          name="fullname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกชื่อ-นามสกุล`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`ชื่อและนามสกุลของคุณ`}
                              onInput={Helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.fullname && (
                          <label htmlFor="" className="tError">
                            {errors.fullname.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group validate ${
                          errors.mobileNo ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">เบอร์โทรศัพท์</div>
                        <Controller
                          name="mobileNo"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกเบอร์โทรศัพท์`,
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                              message: `กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { name, value, onChange } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              displayType="input"
                              format="###-###-####"
                              placeholder={`กรอกเบอร์โทรศัพท์`}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange(name);
                              }}
                            />
                          )}
                        />
                        {errors.mobileNo && (
                          <label htmlFor="" className="tError">
                            {errors.mobileNo.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group validate ${
                          errors.idCard ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">หมายเลขบัตรประชาชน</div>
                        <Controller
                          name="idCard"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกหมายเลขบัตรประชาชน`,
                            pattern: {
                              value: /^\d{1}-\d{4}-\d{5}-\d{2}-\d{1}$/, // /^\d{13}$/,
                              message: `กรุณากรอกหมายเลขบัตรประชาชนให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { name, value, onChange } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              displayType="input"
                              format="#-####-#####-##-#"
                              placeholder={`หมายเลขบัตรประชาชนของคุณ`}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange(name);
                              }}
                            />
                          )}
                        />
                        {errors.idCard && (
                          <label htmlFor="" className="tError">
                            {errors.idCard.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.email ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">อีเมล</div>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            // required: `กรุณากรอกอีเมล`,
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                              message: `กรุณากรอกอีเมลให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="email"
                              placeholder={`กรอกอีเมลของคุณ`}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange(field.name);
                              }}
                            />
                          )}
                        />
                        {errors.email && (
                          <label htmlFor="" className="tError">
                            {errors.email.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group validate ${
                          errors.birthDate ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">วัน/เดือน/ปีเกิด</div>
                        <Controller
                          name="birthDate"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกวัน/เดือน/ปี ค.ศ. ที่เกิด`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="date"
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.birthDate && (
                          <label htmlFor="" className="tError">
                            {errors.birthDate.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow radio">
                      <div
                        className={`control-group ${
                          errors.gender ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">เพศ</div>
                        <Controller
                          name="gender"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณาเลือกเพศ`,
                          }}
                          render={({ field }) => (
                            <div className="tChoice">
                              <label className="control control--radio">
                                ชาย
                                <input
                                  {...field}
                                  type="radio"
                                  value="M"
                                  checked={field.value === "M"}
                                />
                                <div className="control__indicator"></div>
                              </label>
                              <label className="control control--radio">
                                หญิง
                                <input
                                  {...field}
                                  type="radio"
                                  value="F"
                                  checked={field.value === "F"}
                                />
                                <div className="control__indicator"></div>
                              </label>
                              <label className="control control--radio">
                                ไม่ระบุ
                                <input
                                  {...field}
                                  type="radio"
                                  value="O"
                                  checked={field.value === "O"}
                                />
                                <div className="control__indicator"></div>
                              </label>
                            </div>
                          )}
                        />
                        {errors.gender && (
                          <label htmlFor="" className="tError">
                            {errors.gender.message}
                          </label>
                        )}
                      </div>
                    </div>

                    <div className="bRow">
                      <div className="control-group pdpa error">
                        <label className="control control--checkbox ">
                          <p className="">
                            ยอมรับ{" "}
                            <Link
                              to="/term-and-conditions"
                              onClick={() => {
                                setTempDataRegis();
                              }}
                            >
                              ข้อตกลงและเงื่อนไข
                            </Link>
                          </p>
                          <Controller
                            name="isAgreeAndCon"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `กรุณายอมรับเงื่อนไข`,
                            }}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="checkbox"
                                checked={watch("isAgreeAndCon")}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        {errors.isAgreeAndCon && (
                          <label htmlFor="" className="tError">
                            {errors.isAgreeAndCon.message}
                          </label>
                        )}
                        <label className="control control--checkbox">
                          <p>
                            ยอมรับ{" "}
                            <Link
                              to="/privacy-policy"
                              onClick={() => {
                                setTempDataRegis();
                              }}
                            >
                              นโยบายความเป็นส่วนตัว
                            </Link>
                          </p>
                          <Controller
                            name="isPdpa"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `กรุณายอมรับเงื่อนไข`,
                            }}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="checkbox"
                                checked={watch("isPdpa")}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        {errors.isPdpa && (
                          <label htmlFor="" className="tError">
                            {errors.isPdpa.message}
                          </label>
                        )}
                      </div>
                    </div>

                    {/* <p className="tError">Text Error Main Form</p> */}
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  {/* <button
                    className="btn outline"
                    onClick={(e) => {
                      e.preventDefault();
                      goBack("home");
                    }}
                  >
                    ย้อนกลับ
                  </button> */}
                  <button
                    className="btn"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    ต่อไป
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default RegisterUser;
