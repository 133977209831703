import { get, post } from "../Config";

export function searchCustomer({ params }) {
  return get({ url: `/api/sb/v1/customer/search`, params });
}
export function survey({ params }) {
  return get({ url: `/api/sb/v1/survery`, params });
}
export function createCustomer({ data }) {
  return post({ url: `/api/sb/v1/customer/create`, data, timeOut: 20000 });
}
export function updateCustomer({ data }) {
  return post({ url: `/api/sb/v1/customer/update`, data, timeOut: 20000 });
}
export function custProfile({ params }) {
  return get({ url: `/api/sb/v1/customer/profile`, params });
}
// export function Transaction({ params }) {
//   return get({ url: `/api/sb/v1/transaction`, params });
// }

// export function CustCoupons({ params }) {
//   return get({ url: `/api/sb/v1/customer/coupons`, params });
// }
// export function CouponHistory({ params }) {
//   return get({ url: `/api/sb/v1/customer/coupon/history`, params });
// }
// export function CreateCoupon({ data }) {
//   return post({ url: `/api/sb/v1/customer/coupon/create`, data, timeOut: 20000 });
// }

export function OtpRequest({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_request`, data, timeOut: 20000 });
}
export function OtpVerify({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_verify`, data, timeOut: 20000 });
}